<template>
  <div v-if="currentRole === 4 || currentRole === 5" class="absent">
    <v-row no-gutters>
      <v-col md="12">
        <div class="d-flex justify-space-between">
          <h2 class="d-flex">
            <div class="d-flex align-center">Admin Task Report</div>
          </h2>
        </div>
      </v-col>
    </v-row>
    <v-row :class="'absent-header justify-space-between align-center'">
      <v-col>
        <div :class="' d-flex justify-start'">
          <v-select
            v-model="locationID"
            item-text="location"
            append-icon="keyboard_arrow_down"
            item-value="locationID"
            :items="listLocation"
            label="Center"
            outlined
            :class="'select-input'"
            dense
          ></v-select>
          <v-text-field
            type="date"
            v-model="fromDate"
            label="From date"
            :class="'select-input'"
            outlined
            dense
            hide-details
            @input="changeDate"
          />
          <v-text-field
            type="date"
            v-model="toDate"
            label="To date"
            :class="'select-input'"
            outlined
            dense
            hide-details
            @input="changeDate"
          /></div
      ></v-col>
    </v-row>
    <h4>Total : {{ filtered.length }} tasks</h4>
    <template v-if="isLoading">
      <div class="pa-6">
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </div>
    </template>
    <template>
      <v-data-table
        :headers="headers"
        :items="filtered"
        v-if="!isLoading"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        @page-count="pageCount = $event"
        hide-default-footer
        hide-default-header
        class="elevation-0 mb-5 mt-4 pts-table"
        :id="'pts-table'"
        fixed-header
        height="61vh"
        :scroll-left.camel="1000"
      >
        <template v-slot:header="{ props }">
          <thead class="v-data-table-header">
            <tr class="header-commnent">
              <th
                v-for="(header, index) in props.headers"
                :key="header.text + '' + index"
                role="columnheader"
                scope="col"
                class="text-center font-weight-light headTitle"
                :style="'width:' + header.width + '; min-width:' + header.width + ';'"
              >
                <span style="color: white">{{ header.text }}</span>
              </th>
            </tr>
          </thead>
          <tr class="f">
            <td
              v-for="header in props.headers"
              :key="header.value"
              :style="{
                position: 'sticky',
                top: '50px',
                background: '#ffff',
                borderBottom: '1px solid  #d1d4d4',
              }"
            >
              <div :class="'d-flex justify-center'" v-if="filters[header.value]">
                <Autocomplete
                  v-if="filters.hasOwnProperty(header.value)"
                  @setFilter="setFilter"
                  :styleBorder="''"
                  placeholder="select"
                  :keyFilter="header.value"
                  :selectedInit="filters[header.value]"
                  :listItem="groupColumnValueList(header.value)"
                />
              </div>
              <div v-else>
                <td></td>
              </div>
            </td>
          </tr>
        </template>
        <template v-slot:body="{ items }">
          <tbody v-if="filtered.length === 0" style="text-align: center">
            <div class="pt-3" style="position: absolute; left: 50%; transform: translate(-50%, 0)">
              No data available
            </div>
          </tbody>
          <tbody v-else>
            <tr v-for="(item, index) in items" :key="index">
              <template>
                <td>
                  <p>{{ index + 20 * (page - 1) + 1 }}</p>
                </td>
                <td>
                  <p class="text-center">
                    {{ item.category }}
                  </p>
                </td>
                <td>
                  <p class="text-center link" @click="viewClassDetail(item.classID)">
                    {{ item.classCode }}
                  </p>
                </td>
                <td>
                  <p class="text-wrap text-jutify">
                    {{ item.adminTask }}
                  </p>
                </td>
                <td>
                  <p class="text-center">
                    {{ _dayjs(item.adminTaskDate).format('DD/MM/YYYY') }}
                  </p>
                </td>
                <td>
                  <p class="text-center link" @click="viewDetailProfile(item.asigUserId)">
                    {{ item.assignedTo }}
                  </p>
                </td>
                <td>
                  <p class="text-center link" @click="viewDetailProfile(item.qc_userID)">
                    {{ item.qcName }}
                  </p>
                </td>
                <td>
                  <p class="text-center link" @click="viewDetailProfile(item.ec_userID)">
                    {{ item.enName }}
                  </p>
                </td>
                <td>
                  <p class="text-center">
                    {{ item.classTime }}
                  </p>
                </td>
                <td>
                  <p class="text-center">
                    {{ item.roomNo }}
                  </p>
                </td>
                <td>
                  <p class="text-center">
                    {{ _dayjs(item.startDate).format('DD/MM/YYYY') }}
                  </p>
                </td>
                <td>
                  <p class="text-center">
                    {{ _dayjs(item.endDate).format('DD/MM/YYYY') }}
                  </p>
                </td>
                <td>
                  <p class="text-center">
                    {{ item.classStatus }}
                  </p>
                </td>
                <td>
                  <p class="text-center">
                    {{ item.teacherName }}
                  </p>
                </td>
                <td>
                  <p class="text-center">
                    {{ item.tA1Name ? item.tA1Name : '-' }}
                  </p>
                </td>
                <td>
                  <p class="text-center">
                    {{ item.tA2Name ? item.tA2Name : '-' }}
                  </p>
                </td>
                <td>
                  <p class="text-wrap">
                    {{ item.remarks }}
                  </p>
                </td>
              </template>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <div v-if="!isLoading" color="#fff" class="pl-10 mt-3 pr-10">
        <div :style="{ zIndex: '120' }" class="d-flex align-center justify-space-between">
          <div
            :style="{
              width: '150px',
            }"
          >
            <v-select
              outlined
              hide-details
              dense
              :value="itemsPerPage"
              :items="[20, 50, 100, 200]"
              @change="itemsPerPage = parseInt($event, 10)"
              style="height: 32px"
            ></v-select>
          </div>

          <v-pagination
            v-model="page"
            :length="Math.ceil(filtered.length.toFixed() / itemsPerPage)"
            :total-visible="7"
            elevation="0"
          ></v-pagination>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Autocomplete from '@/components/Utils/Autocomplete.vue'
import { report_management } from '@/api/report-management'
// import { account_managerment } from '@/api/account-management'
import dayjs from 'dayjs'
// import localeVi from 'dayjs/locale/vi'

export default {
  components: { Autocomplete },
  data() {
    return {
      currentRole: JSON.parse(localStorage.getItem('currentUser')).userInfo.accountTypeID,
      listTask: [],
      isLoading: false,
      page: 1,
      dayjs: dayjs,
      pageCount: 0,
      itemsPerPage: 100,
      fromDate: new Date().toISOString().split('T')[0],
      toDate: new Date().toISOString().split('T')[0],
      listLocation: [],
      locationID: 0,
      headers: [
        {
          text: 'No',
          value: 'no',
          sortable: false,
          width: '50px',
        },
        {
          text: 'Category',
          value: 'category',
          sortable: false,
          width: '150px',
        },
        {
          text: 'Class Code',
          value: 'classCode',
          sortable: false,
          width: '120px',
        },
        {
          text: 'Admin Task',
          value: 'adminTask',
          sortable: false,
          width: '200px',
        },
        {
          text: 'Admin Task Date',
          value: 'adminTaskDate',
          sortable: false,
          width: '100px',
        },
        {
          text: 'Assigned To',
          value: 'assignedTo',
          sortable: false,
          width: '210px',
        },
        {
          text: 'Class Admin',
          value: 'qcName',
          sortable: false,
          width: '210px',
        },
        {
          text: 'EC',
          value: 'enName',
          sortable: false,
          width: '210px',
        },
        {
          text: 'Class Time',
          value: 'classTime',
          sortable: false,
          width: '100px',
        },
        {
          text: 'Room No',
          value: 'roomNo',
          sortable: false,
          width: '100px',
        },
        {
          text: 'Start Date',
          value: 'startDate',
          sortable: false,
          width: '100px',
        },
        {
          text: 'End Date',
          value: 'endDate',
          sortable: false,
          width: '100px',
        },
        {
          text: 'Class Status',
          value: 'classStatus',
          sortable: false,
          width: '100px',
        },
        {
          text: 'Teacher Name',
          value: 'teacherName',
          sortable: false,
          width: '210px',
        },
        {
          text: 'TA1 Name',
          value: 'tA1Name',
          sortable: false,
          width: '210px',
        },
        {
          text: 'TA2 Name',
          value: 'tA2Name',
          sortable: false,
          width: '210px',
        },
        {
          text: 'Class note',
          value: 'Remark',
          sortable: false,
          width: '300px',
        },
      ],
      filters: {
        category: [],
        adminTask: [],
        adminTaskDate: [],
        classCode: [],
        assignedTo: [],
        qcName: [],
        enName: [],
        classTime: [],
        roomNo: [],
        startDate: [],
        endDate: [],
        classStatus: [],
        teacherName: [],
        tA1Name: [],
        tA2Name: [],
        remarks: [],
      },
    }
  },
  computed: {
    filtered() {
      return this.listTask.filter(d => {
        return Object.keys(this.filters).every(f => {
          return this.filters[f].length < 1 || this.filters[f].includes(d[f])
        })
      })
    },
  },
  setup() {
    return {}
  },
  created() {
    this.listLocation = JSON.parse(localStorage.getItem('currentUser')).locationPermissions
    this.locationID = parseInt(localStorage.getItem('currentCenter'))
    if (localStorage.getItem('ATCenter'))
      this.locationID = parseInt(localStorage.getItem('ATCenter'))
    if (localStorage.getItem('ATTo'))
      this.toDate = dayjs(localStorage.getItem('ATTo')).format('YYYY-MM-DD')
    if (localStorage.getItem('ATFrom'))
      this.fromDate = dayjs(localStorage.getItem('ATFrom')).format('YYYY-MM-DD')
    if (localStorage.getItem('ATFilter'))
      this.filters = JSON.parse(localStorage.getItem('ATFilter'))
    this.init()
    this.page = 1
  },
  mounted() {},

  watch: {
    locationID: function () {
      localStorage.setItem('ATCenter', this.locationID)
      this.init()
    },
  },
  methods: {
    setFilter(objectFilterChange) {
      this.filters = {
        ...this.filters,
        [objectFilterChange.name]: objectFilterChange.filter,
      }
      localStorage.setItem('ATFilter', JSON.stringify(this.filters))
    },
    viewDetailProfile(userId) {
      this.$router.push({
        name: 'account-management-view-account',
        params: {
          userId: userId,
        },
      })
    },
    viewClassDetail(classID) {
      if (classID == null || classID == 0) return
      this.$router.push({
        name: 'class-management-detail',
        query: {
          classID: classID,
        },
      })
    },
    back() {
      window.history.back()
    },
    groupColumnValueList(val) {
      return this.listTask.map(d => d[val]).filter(y => y)
    },

    changeDate() {
      localStorage.setItem('ATFrom', this.fromDate)
      localStorage.setItem('ATTo', this.toDate)
      this.init()
    },
    // async getCenter() {
    //   await account_managerment.getListCenter().then(res => {
    //     if (res) {
    //       res.forEach(el => this.listLocation.push(el))
    //     }
    //   })
    // },
    async init() {
      this.isLoading = true
      await report_management
        .getAdminTask(this.locationID, this.fromDate, this.toDate)
        .then(res => {
          this.listTask = res.sort((a, b) => new Date(b.adminTaskDate) - new Date(a.adminTaskDate))
          //   this.listTask = res.map(res => {
          //     return {
          //       ...res,
          //       testTime: res.testStartTime + '-' + res.testEndTime,
          //       testDate: dayjs(res.testDate).locale(localeVi).format('DD/MM/YYYY'),
          //       crtdDate: dayjs(res.crtdDate).locale(localeVi).format('DD/MM/YYYY'),
          //       comments: {
          //         teacherComments: res.teacherComments,
          //         speakingComments: res.speakingComments,
          //         writtingComments: res.writtingComments,
          //       },
          //     }
          //   })
          this.isLoading = false
        })
    },
    _dayjs: dayjs,
  },
}
</script>

<style lang="scss" scoped>
.select-input {
  max-width: 200px;
  margin-right: 20px !important;
}
.absent {
  padding: 36px;
}
.h {
  background: rgba(0, 0, 0, 0.01);
  position: fixed;
  width: 100%;
  height: 100%;

  top: 0;
  z-index: 1000;
  left: 0;
}

.link {
  cursor: pointer;
  color: #427df2 !important;
}
.link:hover {
  text-decoration: underline;
  font-weight: 700;
}

::v-deep .headTitle {
  background-color: #0b2585 !important;
  height: 30px !important;
  font-size: 14px !important;
  border: 0 !important;
  color: white !important;
}

tbody tr:nth-of-type(odd) {
  // background: rgb(235, 229, 229) !important;
  color: #000 !important;
}
tbody tr:nth-of-type(even) {
  // background: rgb(235, 229, 229) !important;
  background: #000 !important;
}
#pts-table {
  display: block;
  white-space: nowrap;
}
.f td:nth-of-type(1) {
  z-index: 5 !important;
  left: 0;
}
.f td:nth-of-type(2) {
  z-index: 5 !important;
  left: 50px;
}
.f td:nth-of-type(3) {
  z-index: 5 !important;
  left: 200px;
}
.f td:nth-of-type(4) {
  z-index: 5 !important;
  left: 320px;
}
.f td:nth-of-type(5) {
  z-index: 5 !important;
  left: 520px;
}
.f td:nth-of-type(6) {
  z-index: 5 !important;
  left: 620px;
}
#pts-table th {
  padding: 16px 7px;
  text-align: center;
  font-weight: 400;
}
#pts-table td {
  border-bottom: 1px solid #d1d4d4;
}

#pts-table tbody tr:nth-of-type(even) td {
  background-color: #f3faff !important;
}
#pts-table tbody tr:nth-of-type(even):hover td {
  background-color: #dbecf8 !important;
}
#pts-table tbody tr:nth-of-type(odd):hover td {
  background-color: #d7dbdd !important;
}
#pts-table tbody tr:nth-of-type(even) td {
  background-color: #f3faff !important;
}
#pts-table tbody tr:nth-of-type(even) {
  background-color: #eeeeee !important;
}

#pts-table tbody tr td:nth-of-type(1) {
  position: sticky;
  left: 0px;
  z-index: 4;
  background: #fff;
}
#pts-table tbody tr td:nth-of-type(2) {
  position: sticky;
  left: 50px;
  z-index: 4;
  background: #fff;
}
#pts-table tbody tr td:nth-of-type(3) {
  position: sticky;
  left: 200px;
  z-index: 4;
  background: #fff;
}
#pts-table tbody tr td:nth-of-type(4) {
  position: sticky;
  left: 320px;
  z-index: 4;
  background: #fff;
}
#pts-table tbody tr td:nth-of-type(5) {
  position: sticky;
  left: 520px;
  z-index: 4;
  background: #fff;
}
#pts-table tbody tr td:nth-of-type(6) {
  position: sticky;
  left: 620px;
  z-index: 4;
  background: #fff;
}
// #pts-table tr:nth-child(2) {
//   left: 170px;
//   z-index: 4;
//   background: #fff;
// }
#pts-table tbody tr td:nth-child(1) {
  width: 20px;
}
.header-commnent {
  background: #0b2585;
}
.header-commnent th:nth-child(1) {
  z-index: 6;
  position: sticky;
  left: 0px;
}
.header-commnent th:nth-child(2) {
  z-index: 6;
  position: sticky;
  left: 50px;
}
.header-commnent th:nth-child(3) {
  z-index: 6;
  position: sticky;
  left: 200px;
}
.header-commnent th:nth-child(4) {
  z-index: 6;
  position: sticky;
  left: 320px;
}
.header-commnent th:nth-child(5) {
  z-index: 6;
  position: sticky;
  left: 520px;
}
.header-commnent th:nth-child(6) {
  z-index: 6;
  position: sticky;
  left: 620px;
}
</style>
